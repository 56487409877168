import 'core-js/features/dom-collections/for-each';
import 'core-js/features/promise';

export default (el) => {
    el.querySelectorAll('[data-component]').forEach((el) => {
        loadComponent(el.dataset.component, el);
    });
};

export function loadComponent(componentName, element) {
    return import('../' + componentName + '.js').then((component) => {
        component.default(element);
    });
}
